<template>
  <div>
    <v-calendar
      ref="calendar"
      :start="start"
      first-time="07:30"
      last-time="17:30"
      :show-interval-label="showIntervalLabel"
      interval-minutes="5"
      interval-height="5"
      :interval-style="intervalStyle"
      interval-count="129"
      :weekdays="[1, 2, 3, 4, 5]"
      locale="de-CH"
      type="week"
      :events="events"
      :value="focus"
      :event-color="getColor"
      @click:event="(el, index) => showDetails(el)"
    >
      <template v-slot:day-label-header="">&nbsp;</template>
    </v-calendar>
    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-system-bar window>
          Absenzen <v-spacer></v-spacer
          ><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-system-bar
        >

        <AbsenceList
          :items="absencesDialog"
          hideStudent
          @updated="absenceDeleted"
        />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> schliessen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AbsenceList from "common/components/AbsenceList.vue";

function dateForDay(date, time) {
  const d = new Date(date);
  const day = 20 + d.getDay();
  return new Date(`2022-03-${day}T${time}+01:00`);
}

import { addDays } from "common/utils/date";

export default {
  name: "JustificationHeatMap",
  components: { AbsenceList },
  props: ["id", "term"],
  data() {
    return {
      currentDay: 1,
      absences: [],
      dialog: false,
      eventIndex: -1,
      periods: [],
    };
  },

  computed: {
    absencesDialog() {
      if (this.eventIndex < 0 || !this.events[this.eventIndex]) {
        return [];
      }
      return this.events[this.eventIndex].absences;
    },

    focus() {
      return addDays("2022-03-21", this.currentDay - 1);
    },
    start() {
      return "2022-03-21";
    },
    events() {
      const events = [];
      for (const absence of this.absences) {
        const start = dateForDay(absence.date, absence.startTime);
        const end = dateForDay(absence.date, absence.endTime);
        const event = events.find(
          (el) => el.start.getTime() == start.getTime()
        );

        if (!event) {
          events.push({
            name: absence.description + " – 1 Absenz",
            start: start,
            end: end,
            timed: true,
            absences: [absence],
          });
        } else {
          event.absences.push(absence);
          event.name =
            [...new Set(event.absences.map((el) => el.description))].join(
              ", "
            ) +
            " – " +
            event.absences.length +
            " Absenzen";
        }
      }

      return events;
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
    term() {
      this.fetchData();
    },
  },

  methods: {
    async absenceDeleted() {
      if (this.absencesDialog.length == 1) {
        this.dialog = false;
      }
      await this.fetchData();
    },
    async fetchData() {
      if (!this.id || !this.term) {
        return;
      }
      this.periods = await this.apiList({
        resource: "common/period",
      });
      this.absences = await this.apiList({
        resource: "absence/absence",
        query: `student=${this.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
    },
    getColor(item) {
      return `hsl(${140 - 20 * item.absences.length}deg 90% 35%)`;
    },
    intervalStyle(datetime) {
      return this.periods.map((el) => el.startTime).includes(datetime.time)
        ? {}
        : { borderTopColor: "transparent" };
    },
    showDetails(el) {
      if (el.event.absences) {
        this.eventIndex = this.events.findIndex(
          (event) => event.start.getTime() == el.event.start.getTime()
        );
        this.dialog = true;
      }
    },

    showIntervalLabel(datetime) {
      return this.periods.map((el) => el.startTime).includes(datetime.time);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style scoped></style>
